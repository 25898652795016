<template>
  <f7-page class="cart-page">
    <template #fixed>
      <ProductNavigationComponent :title="$t.getTranslation('LBL_CART') || Cart" type="back" :search="false" :cart="false" />

      <f7-actions v-if="loaded" class="userAddressActions">
        <f7-actions-group>
          <f7-actions-button @click="editAdd">{{ $t.getTranslation('LBL_EDIT') }}</f7-actions-button>
          <f7-actions-button @click="deleteAdd">{{ $t.getTranslation('LBL_DELETE') }}</f7-actions-button>
        </f7-actions-group>
      </f7-actions>
    </template>

    <f7-sheet class="coupon-sheet" swipe-to-close backdrop>
      <f7-navbar>
        <f7-nav-left>
          <span>{{ $t.getTranslation('LBL_REDEEM_COUPON') }}</span>
        </f7-nav-left>
      </f7-navbar>

      <!-- ADDED FOR FIXING SCROLLING ISSUE -->
      <f7-page-content>
        <div class="list-wrap list-search-max-height">
          <NoDataFoundComponent v-if="!VoucherList.length && VoucherLoaded" :size="'sm'" :title="$t.getTranslation('LBL_NO_VALID_COUPON_FOUND')" />
          <VoucherCardComponent v-for="item in VoucherList" :data="item" :opac="true" :selected="SelectedVoucher.indexOf(item.VoucherId) > -1" :key="item.UserVoucherId" @click="selectVoucher(item)"></VoucherCardComponent>
        </div>
      </f7-page-content>
    </f7-sheet>

    <f7-sheet class="coupon-code-sheet" swipe-to-close backdrop>
      <f7-navbar>
        <f7-nav-left>
          <span>{{ $t.getTranslation('LBL_ENTER_CODE') }}</span>
        </f7-nav-left>
      </f7-navbar>

      <div>
        <f7-list form no-hairlines style="width: 100%">
          <f7-list-input
            v-model:value="formData.VoucherCode"
            name="VoucherCode"
            :label="$t.getTranslation('LBL_ENTER_CODE')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            outline
            floating-label
            type="text"
            validate
            clear-button
            autocomplete="off"
          >
          </f7-list-input>
        </f7-list>

        <div class="voucher-submit">
          <f7-button fill large @click="checkVoucherCode">{{ $t.getTranslation('LBL_SUBMIT') }}</f7-button>
        </div>
      </div>
    </f7-sheet>

    <f7-sheet class="id-number-sheet" swipe-to-close backdrop>
      <f7-page-content style="padding-top:0px;">
        <f7-navbar>
          <f7-nav-left>
            <span v-if="formData?.Id?.IsEdit">{{ $t.getTranslation('LBL_ENTER_ID_INFORMATION') }}</span>
            <span v-else>{{ $t.getTranslation('LBL_BUYER_INFORMATION') }}</span>
          </f7-nav-left>
          <f7-nav-right style="cursor:pointer" @click="closeIdNumberSheet()">
            <span><font-awesome-icon :icon="['far', 'times']" fixed-width/></span>
          </f7-nav-right>
        </f7-navbar>
        <!-- <div class="list-wrap list-search-max-height"> -->

        <div class="list-wrap list-search-max-height" style="overflow-y:auto;">
          <p v-if="formData?.Id?.IsEdit" style="padding: 0px 20px;white-space:no-wrap;font-size:12px;" class="address-notice">{{ $t.getTranslation('LBL_CREATE_ADDRESS_SUB') }}</p>
           <p v-else style="padding: 0px 20px;white-space:no-wrap;font-size:12px;" class="address-notice">{{ $t.getTranslation('LBL_BUYER_INFORMATION_SUB') }}</p>
          <!-- <p style="padding: 0px 20px;margin:0px;">{{ $t.getTranslation('LBL_ENSURE_NAME_ID_NAME') }}</p> -->

          <f7-list form no-hairlines style="width: 100%">
            <f7-list-input
              v-model:value="formData.Id.FullName"
              name="FullName"
              :label="$t.getTranslation('LBL_ENTER_NAME')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              outline
              floating-label
              type="text"
              validate
              :disabled="!formData.Id.IsEdit"
            >
            </f7-list-input>
          </f7-list>

          <f7-list form no-hairlines style="width: 100%">
            <f7-list-input
              v-model:value="formData.Id.IdNumber"
              name="IdNumber"
              :label="$t.getTranslation('LBL_ENTER_ID_NUMBER')"
              :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
              :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
              outline
              floating-label
              type="text"
              validate
              :disabled="!formData.Id.IsEdit"
            >
            </f7-list-input>
          </f7-list>

          <p style="padding: 0px 20px;white-space:no-wrap;font-size:12px;" v-if="verifyUserIdentification">{{ $t.getTranslation('LBL_I_ACCEPT_SUB') }}</p>

          <div class="voucher-submit">
            <f7-button fill large :disabled="!formData.Id.IsEdit" @click="submitIdNumber()">{{ $t.getTranslation('LBL_SUBMIT') }}</f7-button>
          </div>
        </div>
        <!-- </div> -->
      </f7-page-content>
    </f7-sheet>

    <div class="cart-container" v-if="loaded">
      <div class="container" v-if="Items.length">
        <!-- <div class="title">
          <h1>{{ $t.getTranslation('LBL_DELIVERY_ADDRESS') }}</h1>
        </div> -->

        <div class="delivery-container">
          <div class="cont">
            <div class="item">
              <h3>{{ $t.getTranslation('LBL_DELIVERY_ADDRESS') }}</h3>
            </div>
          </div>
          <div class="cont">
            <div class="delivery-list" @click="editAdd(SelectedAddress)">
              <span v-if="Address.length == 0 && !isLoading">
                <p>
                  {{ $t.getTranslation('LBL_CART_ADDRESS_NOTICE_MESSAGE') }}
                </p>
                <p>
                  {{ $t.getTranslation('LBL_CART_ADDRESS_NOTICE_MESSAGE_SUB') }}
                </p>
              </span>
              <span v-else>
                <span v-if="SelectedAddress?.MobileNumber">
                  <p>{{ SelectedAddress?.LastName }}{{ SelectedAddress?.FirstName }}</p>
                  <p>{{ SelectedAddress?.PrimaryAddress }}, {{ SelectedAddress?.District }}, {{ SelectedAddress?.City }}, {{ SelectedAddress?.Province }}</p>
                  <p>{{ SelectedAddress?.MobileCode }}-{{ SelectedAddress?.MobileNumber }}</p>
                </span>
                <p v-else>
                  {{ $t.getTranslation('LBL_ADDRESS_INFORMATION') }}
                </p>
              </span>
            </div>
            <div class="item" @click="openAddress()">
              <p><font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width /></p>
            </div>
          </div>
          <hr style="border-top: 1px solid rgb(251 250 250);" />
          <div class="cont">
            <div class="item">
              <h3>{{ $t.getTranslation('LBL_ID_INFORMATION') }}</h3>
            </div>
          </div>
          <div class="cont">
            <div class="item">
              <span v-if="(!formData?.Id?.IdNumber || !formData?.Id?.IdNumber == '') && formData?.Id.IsEdit" @click="checkIdNumber()">
                <p>{{ $t.getTranslation('LBL_ENTER_ID_INFORMATION') }}</p>
              </span>
              <span v-else>
                <p>{{ formData.Id?.FullName }}</p>
              </span>
            </div>
            <div class="item" @click="checkIdNumber()">
              <p>{{ formData.Id?.IdNumber }} <font-awesome-icon :icon="['fas', 'chevron-right']" fixed-width /></p>
            </div>
          </div>
        </div>

        <!-- <div class="delivery-address-container">
          <div class="delivery-add" @click="addAddress">
            <font-awesome-icon :icon="['far', 'plus']" fixed-width />
          </div>

          <f7-swiper :slides-per-view="'auto'" :space-between="10">
            <f7-swiper-slide v-for="(add, i) in Address" :key="'dlad' + i" class="delivery-address" :class="{ active: UserAddressKey == add.UserAddressKey }">
              <font-awesome-icon class="delivery-setting" :icon="['far', 'ellipsis-v']" fixed-width @click="openActions(add)" />

              <div @click="selectAdd(add)">
                <p>
                  {{ add.PrimaryAddress }}, {{ add.District }}, {{ add.City }},
                  {{ add.Province }}
                </p>
                <span class="delivery-active">
                  <span v-if="add.IsDefault">{{ $t.getTranslation('LBL_DEFAULT_ADDRESS') }}</span>
                  <font-awesome-icon v-if="UserAddressKey == add.UserAddressKey" :icon="['fas', 'check-circle']" fixed-width />
                </span>
              </div>
            </f7-swiper-slide>
            <f7-swiper-slide style="color: #fff" v-if="Address.length == 0 && !isLoading">
              <div class="no-add-notice">
                <font-awesome-icon style="font-size: 25px" class="no-delivery-setting" :icon="['far', 'exclamation-triangle']" fixed-width />
                <div class="no-delivery-active">
                  <h3>
                    {{ $t.getTranslation('LBL_CART_ADDRESS_NOTICE_MESSAGE') }}
                  </h3>
                  <p>
                    {{ $t.getTranslation('LBL_CART_ADDRESS_NOTICE_MESSAGE_SUB') }}
                  </p>
                </div>
              </div>
            </f7-swiper-slide>
          </f7-swiper>
        </div> -->

        <!-- <template v-if="SelectedAddress">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_ADDRESS_INFORMATION') }}</h1>
          </div>
          <div class="selected-delivery-address-cont">
            <p class="sel-count">
              <strong>{{ $t.getTranslation('LBL_CONSIGNEE') }}</strong
              >: {{ SelectedAddress?.LastName }}{{ SelectedAddress?.FirstName }}
            </p>
            <p>
              <strong>{{ $t.getTranslation('LBL_MOBILE_NUMBER') }}</strong
              >: {{ SelectedAddress?.MobileCode }}{{ SelectedAddress?.MobileNumber }}
            </p>
            <p>
              <strong>{{ $t.getTranslation('LBL_ADDRESS') }}</strong
              >: {{ SelectedAddress?.PrimaryAddress }}, {{ SelectedAddress?.District }}, {{ SelectedAddress?.City }}, {{ SelectedAddress?.Province }}
            </p>
          </div>
        </template> -->

        <!-- <div style="cursor:pointer;">
          <div class="title">
            <h1>{{ $t.getTranslation('LBL_ID_INFORMATION') }}</h1>
          </div>
          <div class="selected-delivery-address-cont" @click="checkIdNumber(true)">
            <p class="sel-count">
              <strong>{{ $t.getTranslation('LBL_NAME') }}</strong
              >: {{ formData.Id?.FullName }}
            </p>
            <p class="sel-count">
              <strong>{{ $t.getTranslation('LBL_ID_NUMBER') }}</strong
              >: {{ formData.Id?.IdNumber }}
            </p>
          </div>
        </div> -->

        <div class="content cart-err-content" v-if="ExcludedSkuList.length">
          <h3>
            {{ $t.getTranslation('MSG_ERR_EXCLUDE_SKU_NOT_FOUND_IN_CART') }}
          </h3>
          <div class="note-content">
            <ul>
              <li v-for="prod in ExcludedSkuList" :key="prod.ProductId">
                {{ helpers.getAttribute(prod, $t.getLanguage(), 'Name') }}
              </li>
            </ul>
          </div>
        </div>

        <template v-for="(group, image) in Items" :key="'pr_' + image">
          <div class="checkbox-label">
            <f7-checkbox v-model:checked="SelectedMerchant[group.SupplierCode]" @change="changeMerchant(group)" />
            <div style="margin-left: 10px">
              {{ renderMerchantName(group.Items, group.SupplierCode) }}
            </div>
          </div>

          <template v-for="(product, ind) in group.Items" :key="'cartitem_' + ind">
            <div class="cartitem-content">
              <f7-checkbox v-model:checked="Products[product.ProductId]" @change="changeProd(product, group)" />
              <CartProductCardComponent v-if="getOrderItem(product)" :check="callCheck" :order="getOrderItem(product)" :data="product" style="flex: 1; margin-left: 10px" />
            </div>
          </template>
        </template>

        <div class="cart-info">
          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_QUANTITY') }}</h3>
            <h3>
              {{ (typeof Order.TotalItems !== 'undefined' && Order.TotalItems) || '---' }}
              {{ $t.getTranslation('LBL_UNITS') }}
            </h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_TOTAL_BOTTLES') }}</h3>
            <h3>
              {{ (typeof Order.TotalBottles !== 'undefined' && Order.TotalBottles) || '---' }}
              {{ $t.getTranslation('LBL_PCS') }}
            </h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SUB_TOTAL') }}</h3>
            <h3>
              {{ (typeof Order.TotalPrice !== 'undefined' && helpers.formatPrice(Order.TotalPrice, Order.CurrencyCode)) || '---' }}
            </h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_SHIPPING_FEE') }}</h3>
            <h3>
              {{ (typeof Order.TotalShipping !== 'undefined' && helpers.formatPrice(Order.TotalShipping, Order.CurrencyCode)) || '---' }}
            </h3>
          </div>

          <div class="content" v-if="!$configs?.isTaxInclusive">
            <h3>{{ $t.getTranslation('LBL_ESTIMATED_ORDER_TAX') }}</h3>
            <h3>
              {{ (typeof Order.TotalOrderTax !== 'undefined' && helpers.formatPrice(Order.TotalOrderTax, Order.CurrencyCode)) || '---' }}
            </h3>
          </div>

          <div class="content" v-if="Order.PlatformDiscount">
            <h3>{{ $t.getTranslation('LBL_PLATFORM_DISCOUNT') }}</h3>
            <h3>
              {{ helpers.formatPrice(Order.PlatformDiscount.Amount, Order.CurrencyCode) }}
            </h3>
          </div>

          <div v-if="$configs.couponEnabled && ProductsNotDiscounted.length == 0" class="content" :class="{ 'no-border-bottom': Order?.VoucherList?.length }">
            <h3>{{ $t.getTranslation('LBL_COUPON') }}</h3>
            <h3>
              <span v-if="Order?.VoucherList?.length">{{ helpers.formatPrice(Order?.VoucherListAmount, Order.CurrencyCode) }}</span>
              <f7-link v-else @click="showAttachment('coupon')">{{ $t.getTranslation('LBL_REDEEM_COUPON') }}</f7-link>
            </h3>
          </div>

          <div class="content" v-if="Order?.VoucherList?.length">
            <h3></h3>
            <h3>
              <f7-link @click="showAttachment('coupon')">{{ $t.getTranslation('LBL_CHANGE_COUPON') }}</f7-link>
            </h3>
          </div>

          <div v-if="$configs.couponEnabled && ProductsNotDiscounted.length == 0" class="content" :class="{ 'no-border-bottom': Order.VoucherCode }">
            <h3>{{ $t.getTranslation('LBL_COUPON_CODE') }}</h3>
            <h3>
              <span v-if="Order.VoucherCode">{{ helpers.formatPrice(Order.VoucherCode.Amount, Order.CurrencyCode) }}</span>
              <f7-link v-else @click="showAttachment('coupon-code')">{{ $t.getTranslation('LBL_ENTER_CODE') }}</f7-link>
            </h3>
          </div>

          <div class="content" v-if="Order.VoucherCode">
            <h3></h3>
            <h3>
              <f7-link @click="removeCoupon">{{ $t.getTranslation('LBL_REMOVE_COUPON_CODE') }}</f7-link>
            </h3>
          </div>

          <div class="content">
            <h3>{{ $t.getTranslation('LBL_GRAND_TOTAL') }}</h3>
            <h3>
              {{ (typeof Order.GrandTotal !== 'undefined' && helpers.formatPrice(Order.GrandTotal, Order.CurrencyCode)) || '---' }}
            </h3>
          </div>
        </div>

        <div class="title" v-if="SplitOrderCounter == 0">
          <h1>{{ $t.getTranslation('LBL_PAYMENT_METHOD') }}</h1>
        </div>

        <ul class="paymentmethods" v-if="SplitOrderCounter == 0">
          <li v-for="payment in PaymentMethodList" :key="payment.key" class="payment" :class="{ active: PaymentMethod == payment.key }" @click="selectPayment(payment.key)">
            <div class="disp-flex">
              <font-awesome-icon :icon="payment.icon" fixed-width />
              <h1>{{ payment.label }}</h1>
            </div>

            <span v-if="PaymentMethod == payment.key" class="default-payment">
              <font-awesome-icon :icon="['fas', 'check-circle']" fixed-width />
              {{ $t.getTranslation('LBL_SELECTED_PAYMENT') }}
            </span>
          </li>
        </ul>

        <!-- <div style="padding: 10px; margin-top: 10px; font-size: 12px; display: flex">
          <f7-checkbox v-model:checked="isAccepted" />
          <div style="margin-left: 20px">{{ $t.getTranslation('MSG_AWARE_CHECKOUT_CROSS_BORDER') }}</div>
        </div> -->
        <!-- <f7-button fill large :disabled="!isAccepted" @click="checkout">{{ $t.getTranslation('LBL_CHECKOUT') }}</f7-button> -->
        <f7-button fill large @click="checkout">{{ $t.getTranslation('LBL_CHECKOUT') }}</f7-button>

        <div class="discount-content" v-if="Order.PlatformDiscount && Order.PlatformDiscount.TierScripts">
          <div class="order-discount-one">
            <div class="order-discount-one-inner"></div>
          </div>

          <div
            class="order-discount-two"
            :style="{
              'background-image': 'url(' + require('@/assets/images/coupons/bg-coupon.png') + ')',
              'background-repeat': 'repeat'
            }"
          >
            {{ TierRender(Order.PlatformDiscount.TierScripts, $t.getLanguage()) }}
            <div class="order-discount-two-inner"></div>
          </div>
        </div>

        <!-- <div class="title">
          <h1>{{ $t.getTranslation('LBL_NOTICE') }}!</h1>
        </div>

        <div>
          <p>
            {{ $t.getTranslation('LBL_DELIVERY_COVID_NOTICE_SUB') }}
          </p>
        </div> -->

        <!-- PW-576 NOTICE -->
        <div class="content notice-content">
          <h3>
            {{ $t.getTranslation('LBL_NOTES') }}
          </h3>
          <div class="note-content">
            <p>
              {{ $t.getTranslation('LBL_CHECKOUT_NOTICE') }}
            </p>
            <ul>
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_ONE') }}</li>
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_TWO') }}</li>
              <li>{{ $t.getTranslation('LBL_CHECKOUT_NOTICE_THREE') }}</li>
            </ul>
          </div>
        </div>
      </div>

      <NoDataFoundComponent v-else :size="'sm'" type="full" :title="$t.getTranslation('LBL_NO_ITEMS_ADDED_TO_CART')" />
    </div>
  </f7-page>
</template>

<script>
import { configs, extendedConfigs } from '@/utils/configs'
import { defineComponent, ref, reactive, inject, computed, onMounted, defineAsyncComponent } from 'vue'
import { post, get } from '@/utils/axios'
import { useStore } from '@/store'
import _ from 'lodash'
import { processPayment } from '@/logics/payment'
import { helpers } from '@/utils/helpers.js'
import { f7 } from 'framework7-vue'
// import ProductNavigationComponent from "@/components/navigations/ProductNavigationComponent.vue";
// import CartProductCardComponent from "@/components/cards/CartProductCardComponent.vue";
// import VoucherCardComponent from "@/components/cards/VoucherCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const ProductNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-nav" */ /* webpackMode: "lazy" */ '@/components/navigations/ProductNavigationComponent.vue'))
const CartProductCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "cart-product-card" */ /* webpackMode: "lazy" */ '@/components/cards/CartProductCardComponent.vue'))
const VoucherCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "voucher-card" */ /* webpackMode: "lazy" */ '@/components/cards/VoucherCardComponent.vue'))
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ '@/components/NoDataFoundComponent.vue'))

export default defineComponent({
  name: 'CartPage',
  components: {
    VoucherCardComponent,
    NoDataFoundComponent,
    ProductNavigationComponent,
    CartProductCardComponent
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const store = useStore()

    let loaded = ref(false)

    let Cart = store.getters['cart/getData']
    let userProduct = store.getters['user/getData']?.ProductCode
    let userData = computed(() => store.getters['user/getData'])

    let ExcludedSkuList = ref([])
    let Order = ref({})
    let Products = ref({})
    let SelectedMerchant = ref({})
    let isAccepted = ref(false)
    let VoucherList = ref([])
    let VoucherLoaded = ref(false)
    const SplitOrderCounter = ref([])
    const verifyUserIdentification = ref(false)

    const Address = ref([])

    const PaymentMethodListRaw = ref([])

    const PaymentMethodList = computed(() => {
      let isMini = helpers.isMiniProgram()
      return _.filter(PaymentMethodListRaw.value || [], (r) => {
        return (isMini && r.key == 'WeChatPay_Acc') || !isMini
      })
    })
    const PaymentMethod = ref(null)

    const actionUserAddressKey = ref(null)
    const UserAddressKey = ref(null)

    const isLoading = ref(false)

    let SelectedVoucher = ref([])
    const formData = reactive({
      VoucherCode: '',
      Id: {
        IdNumber: '',
        FullName: '',
        IsEdit: true
      }
    })

    let ProductsNotDiscounted = ref([])
    let HasFixProducts = ref([])
    const $t = inject('$translation')

    const Items = computed(() => store.getters['cart/getGroupedItems'])
    const SelectedAddress = computed(() => {
      return _.find(Address.value, (a) => {
        return a.UserAddressKey == UserAddressKey.value
      })
    })

    const changeMerchant = (group) => {
      if (SelectedMerchant.value[group.SupplierCode]) {
        //check for prods
        var selected = _.filter(group.Items, (r) => {
          return Products.value[r.ProductId]
        })
        if (selected.length == 0) {
          //select all now
          _.each(group.Items, (r) => {
            Products.value[r.ProductId] = true
          })
        }
      } else {
        var selected = _.filter(group.Items, (r) => {
          return Products.value[r.ProductId]
        })
        if (selected.length == group.Items.length) {
          _.each(group.Items, (r) => {
            Products.value[r.ProductId] = false
          })
        }
      }

      callCheck()
    }

    const getOrderItem = (prod) => {
      let found = _.find(Order.value.ItemList, (r) => {
        return r.ProductKey == prod.ProductKey
      })
      return found ? found : null
    }

    const renderMerchantName = (item, suppliercode) => {
      return item.length && item[0].MerchantName ? item[0].MerchantName : suppliercode
    }

    const changeProd = (product, group) => {
      if (Products.value[product.ProductId] && !SelectedMerchant.value[group.SupplierCode]) {
        SelectedMerchant.value[group.SupplierCode] = true
      }

      if (!Products.value[product.ProductId]) {
        var selected = _.filter(group.Items, (r) => {
          return Products.value[r.ProductId]
        })
        if (!selected.length) {
          SelectedMerchant.value[group.SupplierCode] = false
        }
      }

      callCheck()
    }

    const getSelectedProducts = () => {
      let keys = []
      _.each(Products.value, (r, ind) => {
        if (r === true || r == 'true') {
          keys.push(ind)
        }
      })
      return keys
    }

    const callCheck = async (reset, isVoucher, isVoucherCode) => {
      let keys = getSelectedProducts()
      let retData = {
        products: keys.length ? keys.join(',') : 0,
        method: 1,
        address: 1,
        items: 1
      }

      if (SelectedVoucher.value.length) {
        retData.voucherlist = SelectedVoucher.value.join(',')
      }

      if (formData.VoucherCode) {
        retData.vouchercode = formData.VoucherCode
      }

      isLoading.value = true

      let ret = await get('/order/check', retData, true)
      if (ret?.SkuList?.length) {
        let skuRet = await get(`/mobile/product/elastic/list`, {
          page: 1,
          size: ret.SkuList.length,
          ProductIds: _.map(ret.SkuList, (r) => r.ProductId)
        })
        ExcludedSkuList.value = _.map(skuRet?.data || [], (r) => {
          return { ...r, Attributes: r.AttributeList }
        })
      }

      if (!ret?.Order) {
        if (isVoucherCode) formData.VoucherCode = ''
        if (isVoucher && SelectedVoucher.value.length) {
          SelectedVoucher.value.splice(SelectedVoucher.value.length - 1, 1)
        }
        return
      }

      let retIdNumber = await get('/user/idnumber', {}, true)
      if (retIdNumber) {
        formData.Id.IdNumber = retIdNumber.IdNumber
        formData.Id.FullName = retIdNumber.FullName
        if (retIdNumber.IdNumber && retIdNumber.FullName) {
          formData.Id.IsEdit = false
        }
      }

      if (ret?.Order?.ProductsNotDiscounted) {
        ProductsNotDiscounted.value = ret?.Order?.ProductsNotDiscounted
      }
      if (ret?.Order.HasFixProducts) {
        HasFixProducts.value = ret?.Order.HasFixProducts
      }

      Order.value = ret.Order
      SplitOrderCounter.value = ret.Order.SplitOrderCounter

      PaymentMethodListRaw.value = ret.PaymentMethod || []
      if (PaymentMethodListRaw.value.length) {
        PaymentMethod.value = PaymentMethodListRaw.value[0].key
      }

      Address.value = _.sortBy(ret.Address || [], (r) => {
        return r.IsDefault
      }).reverse()

      let Default = _.find(ret.Address || [], (a) => {
        return a.IsDefault
      })

      if (Default && !UserAddressKey.value) {
        UserAddressKey.value = Default.UserAddressKey
      }

      isLoading.value = false
    }

    const TierRender = (arr, code) => {
      let find = _.find(arr, (r) => {
        return r.LanguageCode == code
      })
      return find ? find.Name : ''
    }

    const selectAdd = (add) => {
      UserAddressKey.value = add.UserAddressKey
    }

    const selectPayment = (method) => {
      PaymentMethod.value = method
    }

    const submitIdNumber = async () => {
      for (var k in formData.Id) {
        if (k == 'IsEdit') continue
        if (helpers.isBlank(formData.Id[k])) {
          let name = k.charAt(0).toUpperCase() + k.slice(1)
          helpers.createNotification({
            title: $t.getTranslation('LBL_ERROR'),
            message: $t.getTranslation(`Missing ${name} field`),
            type: 'error'
          })
          return
        }
      }

      let resp = await post('/user/idnumber', { IdNumber: formData.Id.IdNumber.trim(), FullName: formData.Id.FullName.trim() })
      if (resp) {
        helpers.createNotification({
          title: $t.getTranslation('LBL_SUCCESS'),
          message: $t.getTranslation('LBL_SUCCESS_ID_NUMBER'),
          type: 'success'
        })
        await callCheck()
        closeIdNumberSheet()
      }

      return
    }

    const checkout = async () => {
      if (SplitOrderCounter.value == 0 && !PaymentMethod.value) {
        return
      }

      if (HasFixProducts.value.length > 0) {
        //call errror message
        helpers.createNotification({
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation('LBL_ERROR_FIX_QTY_MESSAGE'),
          type: 'error'
        })
        return
      }

      if (formData.Id.IsEdit || helpers.isBlank(formData.Id.IdNumber)) {
        helpers.createNotification({
          title: $t.getTranslation('LBL_ERROR'),
          message: $t.getTranslation(`ERR_MISSING_ID_NUMBER`),
          type: 'error'
        })
        return
      }

      let keys = getSelectedProducts()
      let resp = await post('/order/pre/checkout', { products: keys, check: true })
      if (!resp) {
        callCheck()
        return
      }

      //store.dispatch('cart/setData', { CHECKOUT_ITEMS: keys, VOUCHER: SelectedVoucher.value.length ? SelectedVoucher.value.join(',') : null, VOUCHER_CODE: formData.VoucherCode || null })
      //props.f7router.navigate({ name: 'orderCheckoutPage' })

      //CODES FROM CHECKOUT PAGE:
      let VOUCHER = SelectedVoucher.value.length ? SelectedVoucher.value.join(',') : null
      let VOUCHER_CODE = formData.VoucherCode || null
      let CHECKOUT_ITEMS = keys

      let ret = await post('/order/checkout', {
        voucherlist: VOUCHER,
        vouchercode: VOUCHER_CODE,
        products: CHECKOUT_ITEMS,
        UserAddressKey: UserAddressKey.value,
        PaymentMethod: PaymentMethod.value
      })
      if (!ret) return

      if (ret?.SplitOrderCounter > 0) {
        let subOrders = await get('/order/list', { ParentOrderKey: ret.OrderKey })
        subOrders = subOrders.List
        subOrders = _.orderBy(subOrders, ['OrderId'], ['asc'])
        return (window.location.href = `/order/view/${subOrders[0].OrderKey}`)
      }
      await processPayment(PaymentMethod.value, ret.OrderKey)
      verifyUserIdentification.value = false
    }

    onMounted(async () => {
      await store.dispatch('cart/fetchData', { all: true })
      loaded.value = true
      //auto select from Items list
      _.each(Items.value, (group) => {
        SelectedMerchant.value[group.SupplierCode] = true
        _.each(group.Items, (prod) => {
          Products.value[prod.ProductId] = true
        })
      })

      if (props.f7router?.currentRoute?.query?.uakey) {
        UserAddressKey.value = props.f7router.currentRoute.query.uakey
      }

      await callCheck()
    })

    const checkVoucherCode = () => {
      if (!formData.VoucherCode) return
      f7.sheet.get('.coupon-code-sheet').close()
      callCheck(false, false, true)
    }

    const checkIdNumber = (isFalse) => {
      // if (!formData?.Id?.IsEdit) {
      //   return
      // }
      f7.sheet.get('.id-number-sheet').open()
      callCheck(false, false, true)
    }

    const removeCoupon = () => {
      formData.VoucherCode = ''
      callCheck()
    }

    const selectVoucher = async (item) => {
      let found = _.findIndex(SelectedVoucher.value, (r) => {
        return r == item.VoucherId
      })
      if (found > -1) {
        SelectedVoucher.value.splice(found, 1)
      } else {
        SelectedVoucher.value.push(item.VoucherId)
      }

      f7.sheet.get('.coupon-sheet').close()
      callCheck(false, true)
    }

    const getCoupons = async () => {
      VoucherLoaded.value = false

      let amount = Order.value.TotalPrice
      let keys = getSelectedProducts()

      let vouchers = _.map(SelectedVoucher.value, (r) => {
        return r
      })
      if (Order?.value?.VoucherCode) {
        vouchers.push(Order.value.VoucherCode.VoucherId)
      }

      let ret = await get(`/user/coupon`, {
        vouchers: vouchers.length ? vouchers.join(',') : '',
        products: keys.length ? keys.join(',') : 0,
        amount: amount
      })

      VoucherList.value = ret.List || []
      VoucherLoaded.value = true
    }

    const showAttachment = (mode) => {
      let SaleNoCouponCtr = 0
      for (const item of Order.value.ItemList) {
        if (item.ProductSaleStatus == 'ACTIVE' && item.IsCoupon <= 0) {
          SaleNoCouponCtr = SaleNoCouponCtr + 1
        }
      }

      if (SaleNoCouponCtr > 0) {
        return
      }

      if (!Order.value.TotalPrice) return
      f7.sheet.get(`.${mode}-sheet`).open()
      //for refresh
      if (mode == 'coupon') {
        VoucherList.value = [] //reset
        getCoupons()
      }
    }

    const closePopup = (id) => {
      f7.sheet.get('.coupon-sheet').close()
    }

    const closeIdNumberSheet = (verify) => {
      f7.sheet.get('.id-number-sheet').close()
      // if (verify) {
      //   verifyUserIdentification.value = true
      // }
    }

    const addAddress = async () => {
      props.f7router.navigate({ name: 'addressSettings' })
    }

    const editAdd = (add) => {
      if (add) {
        actionUserAddressKey.value = add.UserAddressKey
      } else {
        return
      }
      props.f7router.navigate({
        name: 'addressSettings',
        query: { key: actionUserAddressKey.value }
      })
    }

    const deleteAdd = async () => {
      let ret = await post('/user/address/remove', { key: actionUserAddressKey.value })
      if (!ret) return

      if (actionUserAddressKey.value == UserAddressKey.value) {
        UserAddressKey.value = Address.value[0].UserAddressKey
      }
      callCheck()
    }

    const openActions = async (add) => {
      actionUserAddressKey.value = add.UserAddressKey
      f7?.sheet?.get('.userAddressActions')?.open()
    }

    const openAddress = () => {
      props.f7router.navigate({ name: 'profileAddressPage', query: { cart: 1 } })
    }

    return {
      ExcludedSkuList,
      SelectedAddress,
      openActions,
      editAdd,
      deleteAdd,
      Address,
      SplitOrderCounter,
      selectAdd,
      selectPayment,
      PaymentMethodList,
      PaymentMethod,
      actionUserAddressKey,
      UserAddressKey,
      addAddress,
      loaded,
      SelectedVoucher,
      selectVoucher,
      VoucherLoaded,
      VoucherList,
      showAttachment,
      closePopup,
      TierRender,
      SelectedMerchant,
      isAccepted,
      changeProd,
      changeMerchant,
      Items,
      callCheck,
      getOrderItem,
      Order,
      helpers,
      checkout,
      Products,
      renderMerchantName,
      ProductsNotDiscounted,
      HasFixProducts,
      formData,
      checkVoucherCode,
      removeCoupon,
      isLoading,
      checkIdNumber,
      submitIdNumber,
      closeIdNumberSheet,
      verifyUserIdentification,
      openAddress
    }
  }
})
</script>

<style>
.selected-delivery-address-cont {
  padding: 15px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  margin-bottom: 20px;
}

.list-search-max-height {
  /* padding: 15px;
  max-height: calc(100% - 90px);
  overflow: auto; */
  padding: 0px 20px;
  width: calc(100% - 40px);
}

.coupon-sheet {
  height: 60%;
}

.id-number-sheet {
  height: 60%;
}

.coupon-sheet .page-content {
  padding-top: 0px;
  padding-bottom: 80px;
}

.no-border-bottom {
  border-bottom: 0px !important;
}
.no-address-img {
  position: relative;
  width: 100%;
  height: 135px;
}
.delivery-address-container .no-delivery-address {
  color: #fff;
  padding: 10px;
  position: absolute;
  display: flex;
  flex-direction: row;
}
.no-delivery-setting {
  font-size: 1.5em;
  padding-top: 5px;
  padding-left: 5px;
}
.no-delivery-active {
  width: 270px;
  padding-left: 5px;
  padding-right: 5px;
}
.notice-content {
  padding: 20px 0px;
}
.notice-content h3 {
  color: #fff;
  background: #ffb319;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.note-content {
  background: rgb(255 179 25 / 15%);
  padding: 20px 20px 10px 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.note-content p {
  font-size: 14px;
  font-weight: 600;
  margin-top: 0px;
}
.note-content ul {
  list-style-type: decimal;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 15px;
  font-weight: 600;
}
.coupon-sheet span {
  padding-left: 5px;
}
.coupon-code-sheet span {
  padding-left: 5px;
}
.voucher-submit {
  padding: 15px;
}
.delivery-address-container {
  height: 125px;
  margin-bottom: 10px;
}
.no-add-notice {
  background-image: url('../../assets/images/no-address3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 15px 20px 5px 20px;
  width: 270px;
  height: 150px;
}
.no-delivery-active h3 {
  font-weight: 700;
  font-size: 13px;
  height: 10px;
  padding-top: 10px;
}
.no-delivery-active p {
  font-weight: 600;
}
.cart-err-content {
  margin-bottom: 20px;
}
.cart-err-content h3 {
  color: #fff;
  background: #ffb319;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 14px;
  font-weight: normal;
}
.selected-delivery-address-cont p {
  margin: 0px;
}
.selected-delivery-address-cont .sel-count {
  margin-top: 10px;
}
.checkbox-label {
  padding: 10px;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cartitem-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.discount-content {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.order-discount-one {
  width: 50px;
  background: #eaeaea;
  border-right: 2px dashed #fff;
  align-items: center;
  display: flex;
  background: #382d3e;
}
.order-discount-one-inner {
  margin-left: -10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
}
.order-discount-two {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px;
  color: #fff;
  /* background-image: url("../../assets/images/coupons/bg-coupon.png"); */
  /* background-repeat: repeat; */
}
.order-discount-two-inner {
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
}

.delivery-container {
  width: calc(100% - 20px);
  background: rgb(255, 255, 255);
  min-height: 100px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  padding: 10px;
}

.delivery-container > .cont {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.delivery-container > .cont > .item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delivery-container > .cont > .item > p {
  margin: 0px;
}

.delivery-list {
  cursor: pointer;
}

.delivery-list p,
h4 {
  margin: 0px;
}
</style>
